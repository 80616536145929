import { Pagination } from "@mui/material";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import clearEmptyProp from "../../../../Helpers/ClearEmptyProp";
import { decrypt } from "../../../../Helpers/Secret";
import {
  fetchUserWallet,
  payWithBankTransfer,
  updateIban,
} from "../../../../Redux/Actions/wallet/wallet.action";
import { toServerTime } from "../../../../Services/Timezone";
import { exportUserWallet } from "../../../../Services/api/Roles/RolesProvider";
import { getListType } from "../../../../Services/api/TypeFile/TypeFileProvider";
import deleteIcon from "../../../../assets/icons/delete.svg";
import HomeIcon from "../../../../assets/icons/home.svg";
import { ReactComponent as ReopenIcon } from "../../../../assets/icons/reopen.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import Input from "../../../Shared/Components/Input/Input";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";
import PaymentWay from "../../../Shared/Components/Payment/PaymentWay";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import Title from "../../../Shared/Components/Title/Title";
import classes from "./walletList.module.css";
import WalletListTable from "./walletListTable";
import { filterActions } from "./walletListTableActions";

function Wallet(props) {
  require("./wallet.css");

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    isLoading,
    walletDashboard,
    walletMeta,
    walletTransactions,
    walletCredit,
    walletIban,
  } = useSelector((state) => state.userWalletReducer);

  const paidEntrypopupRef = useRef();
  const ibanModalRef = useRef();

  const [showIbanModal, setShowIbanModal] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const { phoneCode } = useSelector((state) => state.exhibitionReducer);
  const [dataToExport, setDataToExport] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [dashboard, setDashboard] = useState({});
  const [listTypeFile, setListTypeFile] = useState([]);
  const [transferDateHours, setTransferDateHours] = useState(0);
  const [transferDateMinutes, setTransferDateMinutes] = useState(0);
  const roles = JSON.parse(decrypt(localStorage.getItem("roles")) || "[]");

  const getListTypeFile = () => {
    if (!roles) return;
    const myPromise = new Promise((resolve, reject) => {
      getListType().then(
        (res) => {
          setListTypeFile(res?.data?.data);
          resolve();
        },
        (error) => {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("programs.failed_loading")}
            </span>
          );
          reject();
        }
      );
    });

    return myPromise;
  };

  useEffect(() => {
    getListTypeFile();
  }, []);

  const getTypeFile = (searchType) => {
    return listTypeFile.filter((file) => file.type === searchType);
  };

  const statistics = [
    {
      label: t("wallet.label.WDcurrentamount"),
      value: dashboard.total,
      color: "#046C77",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
          <path
            id="check-circle_4_"
            data-name="check-circle (4)"
            d="M51.5,25.725a2.825,2.825,0,0,1-.04,4L38.946,42.008a11.251,11.251,0,0,1-15.766.062L17.81,36.792a2.827,2.827,0,0,1,3.964-4.032l5.352,5.262a5.618,5.618,0,0,0,7.863-.051L47.5,25.688a2.825,2.825,0,0,1,4,.037ZM67.86,33.93A33.93,33.93,0,1,1,33.93,0,33.968,33.968,0,0,1,67.86,33.93Zm-5.655,0A28.275,28.275,0,1,0,33.93,62.205,28.307,28.307,0,0,0,62.205,33.93Z"
            fill="#fff"
          />
        </svg>
      ),
    },
    {
      label: t("wallet.label.WDdeposits"),
      value: dashboard.credit,
      color: "#046C77dd",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
          <path
            id="add_1_"
            data-name="add (1)"
            d="M33.93,0A33.93,33.93,0,1,0,67.86,33.93,33.93,33.93,0,0,0,33.93,0Zm0,62.205A28.275,28.275,0,1,1,62.205,33.93,28.275,28.275,0,0,1,33.93,62.205ZM48.068,33.93a2.828,2.828,0,0,1-2.828,2.828H36.758V45.24a2.828,2.828,0,1,1-5.655,0V36.758H22.62a2.828,2.828,0,0,1,0-5.655H31.1V22.62a2.828,2.828,0,1,1,5.655,0V31.1H45.24A2.828,2.828,0,0,1,48.068,33.93Z"
            fill="#fff"
          />
        </svg>
      ),
    },
    {
      label: t("wallet.label.WDwithdrawal"),
      value: dashboard.payment,
      color: "#046C77cc",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.857 67.863">
          <path
            id="user-pen"
            d="M25.448,33.93A16.965,16.965,0,1,0,8.483,16.965,16.982,16.982,0,0,0,25.448,33.93Zm0-28.275a11.31,11.31,0,1,1-11.31,11.31A11.321,11.321,0,0,1,25.448,5.655ZM30.4,45.862A19.815,19.815,0,0,0,5.655,65.033a2.828,2.828,0,1,1-5.655,0A25.465,25.465,0,0,1,31.81,40.388,2.828,2.828,0,0,1,30.4,45.865ZM65.375,33.588a8.684,8.684,0,0,0-12,0L34.414,52.552a11.242,11.242,0,0,0-3.314,8v4.484a2.829,2.829,0,0,0,2.828,2.828h4.484a11.235,11.235,0,0,0,8-3.314L65.372,45.585a8.485,8.485,0,0,0,0-12Zm-4,8L42.407,60.548a5.607,5.607,0,0,1-4,1.657H36.752V60.548a5.617,5.617,0,0,1,1.657-4L57.376,37.586a2.893,2.893,0,0,1,4,0,2.826,2.826,0,0,1,0,4Z"
            fill="#fff"
          />
        </svg>
      ),
    },
    {
      label: t("wallet.label.WDrecovery"),
      value: dashboard.refund,
      color: "#046C77bb",
      icon: <ReopenIcon className="tw-h-full tw-w-full tw-fill-white" />,
    },
  ];

  const sortActionHandler = async (columnKey) => {
    if (sortColumn == columnKey) {
      setSortOrder((prev) => (prev == "asc" ? "desc" : "asc"));
    } else {
      setSortOrder("asc");
    }
    setSortColumn(columnKey);
  };

  const getSystemUsersListHandler = () => {
    const filter = {};
    !Object.keys(walletDashboard).length && (filter.dashboard = "1");
    dispatch(
      fetchUserWallet({
        page: currentPage,
        perPage: 10,
        filter,
      })
    );
  };

  useEffect(() => {
    getSystemUsersListHandler();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied, sortColumn, sortOrder]);

  useEffect(() => {
    setDashboard(walletDashboard);
  }, [walletDashboard, walletTransactions]);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "home",
      page: t("account_info"),
      pagePath: "/private-profile",
    },
    {
      id: "wallet",
      page: t("wallet.label.wallet"),
      active: true,
    },
  ];

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      await exportUserWallet(clearEmptyProp(dataToExport), type);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  const entryPaidModalHandler = () => {
    setIsOpen(false);
    paidEntrypopupRef.current.dismissModal();
  };

  const openPaidEntryModalHandler = () => {
    setIsOpen(true);
    paidEntrypopupRef?.current?.showModal();
  };

  const openIbanModalHandler = () => {
    setShowIbanModal(true);
    ibanModalRef?.current?.showModal();
  };

  const closeIbanModalHandler = () => {
    setShowIbanModal(false);
    ibanModalRef?.current?.dismissModal();
  };

  const handleSubmitIban = async (values) => {
    await dispatch(updateIban(values));
    closeIbanModalHandler();
  };

  const handleWallertPayment = async () => {
    const walletPaymentFormData = new FormData();
    walletPaymentFormData.append("payment_type", "wallet");
    entryPaidModalHandler();
  };

  useEffect(() => {
    if (walletCredit.status === 201 && walletCredit.data.status) {
      getSystemUsersListHandler();
      setIsSubmitted(false);
    }
    setIsSubmitted(false);
  }, [walletCredit]);

  const currentDate = () => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();

    setTransferDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
    setTransferDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
  };

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const formatTime = (t) => {
    let hours = "" + t.getHours(),
      minutes = "" + t.getMinutes(),
      second = "00";

    hours = hours === "0" ? "00" : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return [hours, minutes, second].join(":");
  };

  useEffect(() => {
    currentDate();
    setLoading(false);
  }, []);

  return (
    <>
      <div className={classes["system-users-list"]}>
        {(isExportLoading || loading) && (
          <SkeletonCardOverlay skeletonWidth="100" />
        )}
        <div className="container-fluid">
          <div className={classes["system-users-list__container"]}>
            <div className="row" id="content-header-bar">
              <div className="col-12">
                <Breadcrumb list={breadcrumbList} />
              </div>
            </div>
            {isLoading ? (
              <div className="container-fluid">
                <div
                  style={{
                    height: "25vh",
                  }}
                >
                  <SkeletonCard />
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "98%",
                  margin: "auto",
                }}
                className="tw-bg-white tw-rounded tw-shadow tw-p-8 tw-space-y-4 container-fluid"
              >
                <div className="tw-text-teal-700 tw-text-2xl">
                  {t("general.statistics")}
                </div>
                <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4">
                  {statistics.map((item) => (
                    <div
                      className="tw-flex tw-items-center tw-space-s-4 tw-p-4 tw-text-white tw-rounded"
                      style={{ backgroundColor: item.color }}
                    >
                      <div className="tw-h-10 tw-w-10">{item.icon}</div>
                      <div>
                        <div className="tw-text-2xl">{item.value}</div>
                        <div className="tw-text-sm">{item.label}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <MainBox>
                  <MainBoxHead
                    title={t("wallet.label.wallet")}
                    actions={filterActions({
                      openModal: () => {
                        openPaidEntryModalHandler();
                      },
                      openIbanModal: () => {
                        openIbanModalHandler();
                      },
                      exportPdf: () => {
                        exportHandler("pdf");
                      },
                      exportExcel: () => {
                        exportHandler("xlsx");
                      },
                    })}
                  />
                  <WalletListTable sortAction={sortActionHandler} />
                  <Pagination
                    count={walletMeta?.totalPages || 0}
                    showFirstButton
                    showLastButton
                    variant="outlined"
                    shape="rounded"
                    className="main-pagination"
                    page={currentPage}
                    onChange={(e, page) => {
                      setCurrentPage(page);
                    }}
                  />
                </MainBox>
              </div>
            </div>
          </div>
        </div>
        <BasicModal ref={paidEntrypopupRef}>
          {isOpen && (
            <MainBox className="border-8 w-60 m-50">
              <div className="popup-header">
                <Title title={t("Exhibition.payment_process")} />
                <img
                  src={deleteIcon}
                  alt="close"
                  onClick={entryPaidModalHandler}
                  className="pointer"
                />
              </div>
              <Formik
                initialValues={{
                  account_holder_name: "",
                  phone_number: "",
                  bank_name: "",
                  iban_number: "",
                  payment_proof_img_name: {},
                  amount: "",
                  transfer_date: setHours(
                    setMinutes(new Date(), transferDateMinutes),
                    transferDateHours
                  ),
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  setLoading(true);
                  setSubmitting(true);
                  setIsSubmitted(true);
                  let paymentFormData = new FormData();

                  paymentFormData.append("name", values.account_holder_name);
                  paymentFormData.append(
                    "mobile",
                    phoneCode + values.phone_number
                  );
                  paymentFormData.append("bank", values.bank_name);
                  paymentFormData.append("iban", values.iban_number);

                  paymentFormData.append(
                    "payment_proof",
                    values.payment_proof_img_name.file
                  );

                  paymentFormData.append("amount", values.amount);
                  const transferDate = toServerTime(
                    formatDate(values.transfer_date) +
                      " " +
                      formatTime(values.transfer_date)
                  );
                  paymentFormData.append(
                    "transfer_date",
                    transferDate.split(" ")[0]
                  );

                  await dispatch(payWithBankTransfer(paymentFormData));
                  entryPaidModalHandler();
                  setSubmitting(false);
                  setLoading(false);
                }}
                validate={(values) => {
                  setHasSubmitted(true);
                  const errors = {};

                  if (!values.account_holder_name) {
                    errors.account_holder_name = t(
                      "Exhibition.errors.required"
                    );
                  }

                  if (!values.phone_number) {
                    errors.phone_number = t("Exhibition.errors.required");
                  } else if (values.phone_number.toString().length <= 7) {
                    errors.phone_number = t("Exhibition.errors.lengthrequired");
                  } else if (values.phone_number.toString().length >= 15) {
                    errors.phone_number = t("Exhibition.errors.lengthrequired");
                  } else if (!phoneCode) {
                    errors.phone_number = t("Exhibition.errors.coderequired");
                  }

                  if (!values.bank_name) {
                    errors.bank_name = t("Exhibition.errors.required");
                  }
                  if (!values.amount) {
                    errors.amount = t("Exhibition.errors.required");
                  }

                  if (!values.iban_number) {
                    errors.iban_number = t("Exhibition.errors.required");
                  } else if (
                    values.iban_number.length < 20 ||
                    values.iban_number.length > 34
                  ) {
                    errors.iban_number = t("Exhibition.errors.ibanrequired");
                  }

                  if (JSON.stringify(values.payment_proof_img_name) === "{}") {
                    errors.bank_transfer_image = t(
                      "Exhibition.errors.required"
                    );
                  }
                  setIsSubmitted(false);

                  return errors;
                }}
                validateOnChange={hasSubmitted}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <PaymentWay
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                    label=""
                    hasSubmitted={isSubmitted}
                    setFieldValue={setFieldValue}
                    onSubmit={handleSubmit}
                    walletAction={handleWallertPayment}
                    getTypeFile={getTypeFile}
                    userWallet={true}
                    currentDate={currentDate}
                    setTransferDateHours={setTransferDateHours}
                    setTransferDateMinutes={setTransferDateMinutes}
                  />
                )}
              </Formik>
            </MainBox>
          )}
        </BasicModal>
        <BasicModal ref={ibanModalRef}>
          {showIbanModal && (
            <MainBox className="border-8 popupWidth  m-50">
              <div className="popup-header">
                <Title title={t("wallet.label.iban")} />
                <img
                  src={deleteIcon}
                  alt="close"
                  onClick={closeIbanModalHandler}
                  className="pointer"
                />
              </div>
              <Formik
                initialValues={{
                  iban: walletIban || "",
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  await handleSubmitIban(values);
                  setSubmitting(false);
                }}
                validate={(values) => {
                  setHasSubmitted(true);
                  const errors = {};

                  if (!values.iban) {
                    errors.iban = t("crud.errors.required");
                  } else if (
                    values.iban.length <= 20 ||
                    values.iban.length > 34
                  ) {
                    errors.iban = t("dashBoard.label.iban_number");
                  }

                  setIsSubmitted(false);

                  return errors;
                }}
                validateOnChange={hasSubmitted}
              >
                {({ values, errors, handleChange, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="tw-flex tw-flex-col tw-space-y-4">
                      <Input
                        label={t("wallet.label.iban")}
                        placeholder={t("wallet.label.iban")}
                        name="iban"
                        type="text"
                        value={values.iban}
                        onChange={handleChange}
                        error={errors.iban}
                        required
                      />
                      <div className="tw-flex tw-justify-end tw-items-center tw-mt-4">
                        <CustomButton
                          type="submit"
                          colors="#036c77"
                          value={t("Exhibition.save")}
                          disable={false}
                          classes="tw-py-2 tw-px-4 tw-flex tw-items-center tw-justify-center tw-w-56"
                        />
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </MainBox>
          )}
        </BasicModal>
      </div>
    </>
  );
}

export default Wallet;
