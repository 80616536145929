import CircularProgress from "@mui/material/CircularProgress";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { toast } from "react-toastify";
import { LearningAnalysisProviderContext } from "../../../../Providers/LearningAnalysisProvider";
import { getLearningAnalysisRating } from "../../../../Services/api/LearningAnalysis/LearningAnalysisService";
import LearningAnalysisRatingAspectList from "./RatingAspectList/RatingAspectList";

function LearningAnalysisRating(props) {
  require("./Rating.css");
  const { t } = useTranslation();

  const learningAnalysisContext = useContext(LearningAnalysisProviderContext);

  const [selectedScreen, setSelectedScreen] = useState(0);
  const [isLoadingContent, setIsLoadingContent] = useState(true);
  const [ratingBodyHeight, setRatingBodyHeight] = useState(0);
  const [views, setViews] = useState([]);
  const [interactions, setInteractions] = useState([]);
  const [rates, setRates] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const handleScreenChange = (event, newValue) => {
    setSelectedScreen(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const fetchLearningAnalysisRating = async (id = null) => {
    try {
      let rating = await getLearningAnalysisRating(id);
      setIsLoadingContent(false);
      setRates(rating.data.response.rates ? rating.data.response.rates : []);
      setInteractions(rating.data.response.interactions ? rating.data.response.interactions : []);
      setViews(rating.data.response.views ? rating.data.response.views : []);
    } catch (err) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>{t("learning_analysis.failure.loading_rating")}</span>
      );
    }
  };

  useEffect(() => {
    // setRatingBodyHeight(document.getElementById('rating_panel').offsetHeight -
    //     document.getElementById('rating_panel_header').offsetHeight);
    setRatingBodyHeight(document.getElementById("rating_panel").offsetHeight);
    fetchLearningAnalysisRating();
  }, []);

  return (
    <>
      <div className={"rating_panel_wrapper"}>
        <div className={"rating_panel"} id={"rating_panel"}>
          <div className={"rating_panel_header"} id={"rating_panel_header"}>
            <div className={"rating_panel_header_action"}>
              <Select
                name="courses"
                id="courses"
                isClearable={true}
                options={learningAnalysisContext.courses}
                isDisabled={isLoadingContent && learningAnalysisContext.isLoading}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                placeholder={t("learning_analysis.registered.all_courses")}
                onChange={(item) => {
                  setIsLoadingContent(true);
                  if (item) {
                    setSelectedCourse(item.id);
                    fetchLearningAnalysisRating(item.id);
                  } else {
                    setSelectedCourse(null);
                    fetchLearningAnalysisRating();
                  }
                }}
              />
            </div>
            {/*<div className={"rating_panel_header_label"}>*/}
            {/*    <p className={"rating_panel_header_label_report"} style={{opacity: 0}}>{t("learning_analysis.full_report")}</p>*/}
            {/*</div>*/}
          </div>
          <div
            className={"rating_panel_body"}
            style={
              isLoadingContent
                ? {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: ratingBodyHeight,
                  }
                : {}
            }
          >
            {isLoadingContent ? (
              <CircularProgress />
            ) : (
              <>
                <Tabs
                  value={selectedScreen}
                  onChange={handleScreenChange}
                  aria-label="basic tabs example"
                  sx={{
                    marginBottom: 2,
                  }}
                >
                  <Tab label={t("learning_analysis.rating.most_viewed")} {...a11yProps(0)} />
                  <Tab label={t("learning_analysis.rating.most_interaction")} {...a11yProps(1)} />
                  {!selectedCourse ? (
                    <Tab label={t("learning_analysis.rating.highly_rated")} {...a11yProps(2)} />
                  ) : null}
                </Tabs>
                <hr style={{ position: "absolute", top: 31, width: "100%", left: 0 }} />
                <div
                  role="tabpanel"
                  hidden={0 !== selectedScreen}
                  id={`simple-tabpanel-0`}
                  aria-labelledby={`simple-tab-0`}
                >
                  <LearningAnalysisRatingAspectList data={views.slice(0, 6)} />
                </div>

                <div
                  role="tabpanel"
                  hidden={1 !== selectedScreen}
                  id={`simple-tabpanel-1`}
                  aria-labelledby={`simple-tab-2`}
                >
                  <LearningAnalysisRatingAspectList data={interactions.slice(0, 6)} />
                </div>
                {!selectedCourse ? (
                  <div
                    role="tabpanel"
                    hidden={2 !== selectedScreen}
                    id={`simple-tabpanel-2`}
                    aria-labelledby={`simple-tab-2`}
                  >
                    <LearningAnalysisRatingAspectList data={rates.slice(0, 6)} />
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default LearningAnalysisRating;
