import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ChatsList } from ".";
import { usePusherContext } from "../../Contexts/PusherContext";
import { decrypt } from "../../Helpers/Secret";
import {
  getlivechatSession,
  getlivechatSettings,
  getlivechatViewSession,
  livechatChangeMessage,
  postLivechatAutoReply,
  postLivechatSendMessage,
  postLivechatStatus,
  resetLivechat,
} from "../../Redux/Actions/livechat/livechat.action";
import toLocalTimezone from "../../Services/Timezone";
import HomeIcon from "../../assets/icons/home.svg";
import logo from "../../assets/image/Logo Tebx.png";
import i18n from "../../i18n/i18n";
import Breadcrumb from "../Shared/Components/Breadcrumb/Breadcrumb";
import CustomButton from "../Shared/Components/CustomButton/CustomButton";
import LoadingSpinner from "../Shared/Components/LoadingSpinner/LoadingSpinner";
import MainBox from "../Shared/Components/MainBox/MainBox";
import Title from "../Shared/Components/Title/Title";
import ToggleWithLabels from "../Shared/Components/ToggleWithLabels/ToggleWithLabels";
import ChatBubbleInputs from "./ChatBubbleInputs";
import "./containerChat.css";
import classes from "./containerChat.module.css";

function ContainerChat(props) {
  const dispatch = useDispatch();
  const {
    livechat_sessions,
    livechat_setting,
    livechat_view_session,
    livechat_message_change,
    livechat_assign_session,
  } = useSelector((state) => state.livechatReducer);
  const [sessions, setSessions] = useState({});
  const [showChatStatus, setShowChatStatus] = useState(null);
  const [autoStatus, setAutoStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [msgLoading, setMsgLoading] = useState(false);
  const [isReply, setIsReply] = useState(false);
  const history = useHistory();
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [sessionId, setSessionId] = useState();
  const [pusherChannel, setPusherChannel] = useState(null);
  const [replies, setReplies] = useState([]);
  const [sessionOwnerId, setsessionOwnerId] = useState("");
  const currentUserid = localStorage.getItem("userId");

  const { t } = useTranslation();
  var bottomRef = useRef(null);

  const { pusher } = usePusherContext();

  function sendMessage() {
    if (inputMessage.trim() !== "") {
      let formData = new FormData();
      if (localStorage.getItem("chatId") != null)
        formData.append("chat_id", localStorage.getItem("chatId"));
      if (localStorage.getItem("userId") != null)
        formData.append("user_id", localStorage.getItem("userId"));
      if (localStorage.getItem("chat_username") != null)
        formData.append("name", localStorage.getItem("chat_username"));
      if (localStorage.getItem("chat_email") != null)
        formData.append("email", localStorage.getItem("chat_email"));

      formData.append("chat_session_id", sessionId);

      formData.append("message", inputMessage);
      dispatch(postLivechatSendMessage(formData));
      dispatch(livechatChangeMessage());

      messages.push({
        img: localStorage.getItem("img"),
        created_at: new Date(),
        message: inputMessage,
        user_id: localStorage.getItem("userId"),
        direction: "right",
      });
      setInputMessage("");
    }
  }

  const refreshChat = () => {
    fetchMessages(sessionId);
  };

  const handleSeenUpdate = (itemId) => {
    setIsLoading(true);
    const updatedItems = [...sessions];

    const itemIndex = updatedItems.findIndex((item) => item.id === itemId);

    if (itemIndex !== -1) {
      updatedItems[itemIndex].is_seen = true;
      setSessions(updatedItems);
      setIsLoading(false);
    }
  };

  const handleAssignUpdate = (itemId) => {
    setIsLoading(true);
    const updatedItems = [...sessions];

    const itemIndex = updatedItems.findIndex((item) => item.id === itemId);

    if (itemIndex !== -1) {
      updatedItems[itemIndex].is_assigned = true;
      updatedItems[itemIndex].can_reply = true;
      setSessions(updatedItems);
      setIsLoading(false);
    }
  };

  const fetchMessages = (id) => {
    setMsgLoading(true);
    dispatch(getlivechatViewSession(id));
  };
  // TRIGGERED ON MOUNT
  useEffect(() => {
    const channel = pusher.subscribe("bot-chat");
    setPusherChannel(channel);
    channel.bind("App\\Events\\MessageSent", (data) => {
      setSessions((old) => {
        return old;
      });
      if (data.chat_id == sessionId) {
        if (!messages.find((message) => message.id === data.message.id)) {
          setMessages((oldArray) => [...oldArray, data.message]);
        }
      } else {
        setSessions((old) => {
          const index = old.findIndex((i) => i.id == data.chat_id);
          var chat = data.message.chat;

          if (index < 0) {
            chat.is_seen = false;
          } else {
            chat.is_seen = false;
            old.splice(index, 1);
          }

          return [chat, ...old];
        });
      }
    });
    return () => {
      channel?.unbind("App\\Events\\MessageSent");
      pusher?.unsubscribe("bot-chat");
    };
  }, [sessionId]);

  // TRIGGERED ON CHANGE IN "data"
  useEffect(() => {
    if (pusherChannel && pusherChannel.bind) {
      pusherChannel.unbind("message");
      pusherChannel.bind("message", (pusherData) => {
        fetchMessages(sessionId);
      });
    }
    // }, [pusherChannel, data]);
  }, [pusherChannel]);

  const handler = (event) => {
    if (event.key == "Enter") sendMessage();
  };

  useEffect(() => {
    if (livechat_message_change && sessionId) {
      // refreshChat();

      dispatch(livechatChangeMessage());
    }
  }, [livechat_message_change, dispatch, sessionId]);

  useEffect(() => {
    dispatch(getlivechatSession());
    dispatch(getlivechatSettings());
  }, []);

  useEffect(() => {
    if (livechat_sessions.status === 200) {
      setSessions(livechat_sessions.data?.chatsessions);
      setIsLoading(false);
    }
  }, [livechat_sessions]);

  useEffect(() => {
    if (livechat_setting.status === 200) {
      setAutoStatus(livechat_setting.data.setting.settings?.chat_auto_reply);
      setShowChatStatus(livechat_setting.data.setting.settings?.chat_service);
    }
  }, [livechat_setting]);

  useEffect(() => {
    if (livechat_view_session.status === 200) {
      setMessages(livechat_view_session.data.response.messages);
      setReplies(livechat_view_session.data.response.replies);
      setMsgLoading(false);
    }
  }, [livechat_view_session]);

  useEffect(() => {
    if (livechat_assign_session.status === 200) {
      updateObjectById(sessionId, livechat_assign_session.data.chatsession);
    }
  }, [livechat_assign_session]);

  const updateObjectById = (id, newObject) => {
    setSessions((prevObjects) =>
      prevObjects.map((obj) => (obj.id === id ? newObject : obj))
    );
  };

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "chat",
      page: t("chat.title"),
      active: true,
    },
  ];

  const handleSetSession = (id) => {
    setSessionId(id);
    setInputMessage("");
  };

  return (
    <div className="container-fluid">
      <div className={classes.breadcrumb}>
        <Breadcrumb list={breadcrumbList} />
      </div>
      <MainBox>
        <div className={classes.header}>
          <Title title={t("chat.title")} />

          {decrypt(localStorage.getItem("is_admin")) == "true" ? (
            <div className={classes.btns}>
              <CustomButton
                type={"button"}
                value={t("chat.usersup")}
                colors={"#8599b6"}
                action={() => {
                  history.push("/admin/livechat/support-users");
                  dispatch(resetLivechat());
                }}
                classes={classes.customBtn}
              />
              <CustomButton
                type={"button"}
                value={t("chat.msg")}
                colors={"#24b3b9"}
                action={() => {
                  history.push("/admin/livechat/welcome-message");
                  dispatch(resetLivechat());
                }}
                classes={classes.customBtn}
              />
              <CustomButton
                type={"button"}
                value={t("chat.answer_reply")}
                colors={"#056c77"}
                action={() => {
                  history.push("/admin/livechat/answers-reply");
                  dispatch(resetLivechat());
                }}
                classes={classes.customBtn}
              />
              <div className={`tw-rounded-full ${classes.toggleBG}`}>
                <span className={classes.togglelabel}>
                  {t("chat.autoreply")}
                </span>
                <ToggleWithLabels
                  action={() => {
                    setAutoStatus((e) => !e);
                    dispatch(postLivechatAutoReply());
                  }}
                  status={autoStatus ? 1 : 0}
                />
              </div>
              <div className={`tw-rounded-full ${classes.toggleBG}`}>
                <span className={classes.togglelabel}>
                  {t("chat.showchat")}
                </span>
                <ToggleWithLabels
                  action={() => {
                    setShowChatStatus((e) => !e);
                    dispatch(postLivechatStatus());
                  }}
                  status={showChatStatus ? 1 : 0}
                />
              </div>
            </div>
          ) : (
            <CustomButton
              type={"button"}
              value={t("chat.schedule")}
              colors={"#056c77"}
              action={() => {
                history.push(`/livechat/schedule/${currentUserid}`);
                dispatch(resetLivechat());
              }}
              classes={classes.customBtn}
            />
          )}
        </div>
        <div className={classes["d-flex"]}>
          <div
            className={`col-md-12 col-lg-4 col-sm-12 col-xs-12 ${classes.rmPadding}`}
          >
            {isLoading ? (
              <div
                className={`${classes["loading__container"]} ${classes.containerUsersList}`}
              >
                <LoadingSpinner />
              </div>
            ) : (
              <ChatsList
                chats={sessions}
                changeSeen={handleSeenUpdate}
                changeAssign={handleAssignUpdate}
                fetchMessages={fetchMessages}
                setSessionId={handleSetSession}
                setIsReply={setIsReply}
                setsessionOwnerId={setsessionOwnerId}
              />
            )}
          </div>
          <div
            className={`col-md-12 col-lg-8 col-sm-12 col-xs-12 ${classes.livechat} ${classes.rmPadding}`}
          >
            <div className={classes["livechat-card"]}>
              {msgLoading ? (
                <div className={classes["loading__container"]}>
                  <LoadingSpinner />
                </div>
              ) : (
                messages &&
                messages.reverse().map((item) => {
                  const dateTimeAgo = moment(
                    new Date(toLocalTimezone(item.created_at))
                  ).format("YYYY-MM-DD HH:mm:ss");
                  return (
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <div
                        className={`${classes["live-chat-container"]} 
                       ${
                         item.user_id == sessionOwnerId
                           ? classes.left
                           : classes.right
                       }
                      `}
                      >
                        <div
                          className={classes["content-container"]}
                          key={item.id}
                        >
                          {item.img != null || item.chat.user != null ? (
                            <img
                              className={classes["admin_img"]}
                              src={
                                item.img ||
                                item.user.img ||
                                item.chat.user.img ||
                                item.user.image
                              }
                              alt=""
                            ></img>
                          ) : (
                            <img
                              className={classes["admin_img"]}
                              src={logo}
                              alt=""
                            ></img>
                          )}
                          <div
                            className={`${
                              item.user_id == sessionOwnerId
                                ? classes.left
                                : classes.right
                            } ${classes.messageCont}`}
                          >
                            <div
                              className={`${classes.name_date} ${
                                item.user_id == sessionOwnerId
                                  ? classes.left
                                  : classes.right
                              }`}
                            >
                              <span className={classes.namemsg}>
                                {i18n.language === "ar"
                                  ? item?.user?.name_ar
                                    ? item?.user?.name_ar
                                    : localStorage.getItem("name_ar")
                                  : item?.user?.name_en
                                  ? item?.user?.name_en
                                  : localStorage.getItem("name_en")}
                              </span>
                              <span className={classes.date}>
                                {dateTimeAgo}
                              </span>
                            </div>
                            <div>
                              {item.message.startsWith("message-") ? (
                                <a
                                  href={
                                    process.env.REACT_APP_SERVER_PATH +
                                    "storage/chat-manager/" +
                                    item.message
                                  }
                                >
                                  <img
                                    src={
                                      process.env.REACT_APP_SERVER_PATH +
                                      "storage/chat-manager/" +
                                      item.message
                                    }
                                    style={{
                                      width: "auto",
                                      maxHeight: 400,
                                    }}
                                    alt=""
                                  />
                                </a>
                              ) : (
                                <p className={classes.message}>
                                  {item.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
              <div ref={bottomRef} />
            </div>
            <div
              className={`${classes["input-container"]} ${
                props.disabled && classes["input-disable"]
              }`}
            >
              <ChatBubbleInputs
                inputMessage={inputMessage}
                disabled={!isReply}
                setInputMessage={setInputMessage}
                handleOnEnter={(e) => handler(e)}
                messages={messages.reverse()}
                sendMessage={sendMessage}
                selectbox={true}
                options={replies}
              ></ChatBubbleInputs>
            </div>
          </div>
        </div>
      </MainBox>
    </div>
  );
}

export default ContainerChat;
