import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { CourseContext } from "../../../../Services/api/courses/courseContext";
import {
  getCourseDetails,
  getProgramDetails,
} from "../../../../Services/api/courses/courseProvider";
import { getProfile } from "../../../../Services/api/profile/profileProvider";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import TrainingCoursesContent from "./TrainingCourseContent/TrainingCourseContent";

function TrainingCourseDetails({ isProgram, backPath }) {
  require("./TrainingCourseDetails.css");
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const courseDetailsContext = useContext(CourseContext);
  const [subscription, setSubscription] = useState(false);
  const [userSCGHS, setUserSCGHS] = useState(false);
  const [inProgram, setInProgram] = useState(false);

  const getCourseDetailsAPI = () => {
    const getCourseAPI = isProgram ? getProgramDetails : getCourseDetails;
    getCourseAPI(id)
      .then((res) => {
        if (res.status === 200 && res.data.status) {
          courseDetailsContext.setCoupon(
            isProgram ? res.data.program.coupons : res.data.course.coupons
          );
          courseDetailsContext.setCourseDetails(
            isProgram ? res.data.program : res.data.course
          );
          setInProgram(
            isProgram ? false : res.data.course.program_id ? true : false
          );
          courseDetailsContext.setIsLoading(false);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getCourseDetailsAPI();
  }, [subscription]);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getProfile()
        .then((res) => {
          if (res.status === 200 && res.data.status) {
            setUserSCGHS(res.data.user.scghs ? true : false);
          }
        })
        .catch((err) => {});
    } else {
      setUserSCGHS(false);
    }
  }, []);

  return (
    <>
      {courseDetailsContext.isLoading ? (
        <SkeletonCardOverlay />
      ) : (
        <>
          {location.state ? (
            location.state.user !== "trainer" ? (
              <TrainingCoursesContent
                setSubscription={setSubscription}
                userSCGHS={userSCGHS}
                isProgram={isProgram}
                inProgram={inProgram}
                backPath={backPath}
              />
            ) : null
          ) : (
            <TrainingCoursesContent
              setSubscription={setSubscription}
              userSCGHS={userSCGHS}
              isProgram={isProgram}
              inProgram={inProgram}
              backPath={backPath}
            />
          )}
        </>
      )}
    </>
  );
}

export default TrainingCourseDetails;
