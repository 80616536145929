import { Redirect } from "react-router-dom";
import {
  AboutUs,
  AllPrograms,
  ContactUs,
  Exhibition,
  ForgotPassword,
  GenericNotFound,
  HomeCourses,
  MainAuth,
  NewHome,
  PrivacyPolicy,
  SendIpBlockedRequest,
  SurveyQuestions,
  SurveyView,
  TermsAndConditions,
  Trainers,
  TrainingCoursesDetailsProvider,
  UnityIframe,
} from ".";
import FAQListEndUser from "../../Modules/Admin/FAQ/FAQListEndUser";
import FAQUser from "../../Modules/FAQ_s/FAQ_User";
import TrainerInfoDetails from "../../Modules/TrainersInfoDetails/TrainersInfoDetails";
import Guidelines from "../../Modules/guideLines/Guidelines";
import SearchPage from "../../Modules/home/SearchPage/SearchPage";
import Surveys from "../../Modules/trainees/survey/Surveys";

const routesUnprotected = [
  {
    component: NewHome,
    path: "/",
    exact: true,
  },
  {
    component: MainAuth,
    path: "/register",
    exact: true,
  },
  {
    component: MainAuth,
    path: "/login",
    exact: true,
  },
  {
    component: MainAuth,
    path: "/email-verification",
    exact: true,
  },
  {
    component: Trainers,
    path: "/trainers",
    exact: true,
  },
  {
    component: HomeCourses,
    path: "/training-courses",
    exact: true,
  },
  {
    component: () => <HomeCourses isProgram />,
    path: "/training-programs",
    exact: true,
  },
  {
    component: () => <TrainingCoursesDetailsProvider backPath="program" />,
    path: "/training-programs/:programID/details/course/:id/details",
    exact: true,
  },
  {
    component: ContactUs,
    path: "/contactUs",
    exact: true,
  },
  {
    component: AboutUs,
    path: "/about-us",
    exact: true,
  },
  {
    component: Surveys,
    path: "/general-surveys",
    exact: true,
  },
  {
    component: Guidelines,
    path: "/guidelines",
    exact: true,
  },
  {
    component: (props) => <SurveyQuestions {...props} isPublic />,
    path: "/survey/:id/statistics",
    exact: true,
  },
  {
    component: Exhibition,
    path: "/Exhibition",
    exact: true,
  },
  {
    component: (props) => <SurveyView general={true} />,
    path: "/general-surveys/:id",
    exact: true,
  },
  {
    component: (props) => <SurveyView general={true} />,
    path: "/survey/:id/exhibition/:targetExhibitionId",
    exact: true,
  },
  {
    component: SurveyView,
    path: "/survey/:id/course/:courseId",
    exact: true,
  },
  {
    component: UnityIframe,
    path: "/show-exhibition/unity-iframe/:id",
    exact: true,
  },
  {
    component: TermsAndConditions,
    path: "/terms-and-conditions",
    exact: true,
  },
  {
    component: PrivacyPolicy,
    path: "/privacy-policy",
    exact: true,
  },
  {
    component: SearchPage,
    path: "/home-search",
    exact: true,
  },
  {
    component: () => <TrainingCoursesDetailsProvider backPath="training-courses" />,
    path: "/training-courses/:id/details",
    exact: true,
  },
  {
    component: () => <TrainingCoursesDetailsProvider isProgram backPath="training-programs" />,
    path: "/training-programs/:id/details",
    exact: true,
  },
  {
    children: <Redirect to={`/login`} />,
    path: "/training-courses/:id/profile",
  },
  {
    component: ForgotPassword,
    path: "/forgot-password",
    exact: true,
  },
  {
    component: TrainerInfoDetails,
    path: "/trainers/trainer-details/:id",
    exact: true,
  },
  {
    component: SendIpBlockedRequest,
    path: "/send-requests",
    exact: true,
  },
  {
    component: FAQUser,
    path: "/faq/faq-list",
    exact: true,
  },
  {
    component: FAQListEndUser,
    path: "/tickets/faq-list",
    exact: true,
  },
  {
    component: UnityIframe,
    path: "/show-exhibition/unity-iframe/:id",
    exact: true,
  },
  {
    component: GenericNotFound,
    path: "/page-not-found",
    exact: true,
  },
];

export default routesUnprotected;
