import { Redirect, Route, Switch } from "react-router-dom";
import routesTrainer from "../../../../routes/trainers/RoutesTrainers";

function Trainer(props) {
  return (
    <Switch>
      {routesTrainer.map((route, index) => (
        <Route exact={route.exact} path={`${props.match.path + route.path}`} component={route.component} />
      ))}
      <Redirect to="/page-not-found" />
    </Switch>
  );
}

export default Trainer;
