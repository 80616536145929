import { useTranslation } from "react-i18next";

const Welcome = () => {
  const { t } = useTranslation();

  return (
    localStorage.getItem("token") && (
      <div
        style={{
          height: "40px",
          backgroundColor: "#24b3b9",
          padding: "1rem 2rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          fontSize: "1.2rem",
          fontWeight: "bold",
        }}
      >
        <p>{t("welcome_message")}</p>
      </div>
    )
  );
};

export default Welcome;
