import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { usePusherContext } from "../../../../Contexts/PusherContext";
import { AuthContext } from "../../../../Services/api/auth/AuthContext";
import { getProfileInfo } from "../../../../Services/api/profile/profileProvider";
import NotificationSound from "../../../../assets/sounds/notification.mp3";
import AdminHeader from "../../../Admin/adminHeader/AdminHeader";
import Links from "./Links";

const Header = () => {
  require("./header.css");

  const { pathname, search } = useLocation();
  const root = pathname.split("/")[1];
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const { pusher } = usePusherContext();
  const { livechat_setting } = useSelector((state) => state.livechatReducer);
  const query = new URLSearchParams(search, "search");

  const [chatAllowed, setChatAllowed] = useState();
  const [completedProfile, setCompletedProfile] = useState();

  useEffect(() => {
    if (livechat_setting.status === 200) {
      setChatAllowed(livechat_setting.data.setting.chat_allowed);
    }
  }, [livechat_setting]);

  useEffect(() => {
    if (root === "admin" || root === "accountant") {
      authContext.setInAdminPanel(true);
    } else {
      authContext.setInAdminPanel(false);
    }
  }, [root, authContext]);

  const listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    const scrolled = winScroll;

    if (document.getElementById("tajah-target-header")) {
      if (scrolled >= 111) {
        document.getElementById("tajah-target-header").classList.add("navbar-fixed-top");
      } else {
        document.getElementById("tajah-target-header").classList.remove("navbar-fixed-top");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, []);

  useEffect(() => {
    const channel = pusher.subscribe(`ticket-channel-${localStorage.getItem("userId")}`);
    channel.bind("App\\Events\\TicketEvent", (data) => {
      let audio = new Audio(NotificationSound);
      audio.play();
      toast.warning(
        <span style={{ fontSize: 12, fontWeight: "bold" }}>
          {i18n.language === "en" ? data.message_en : data.message_ar}
        </span>
      );
    });

    return () => {
      pusher.unsubscribe(`ticket-channel-${localStorage.getItem("userId")}`);
    };
  }, []);

  useEffect(() => {
    const channel = pusher.subscribe(`comment-channel-${localStorage.getItem("userId")}`);
    channel.bind("App\\Events\\CommentEvent", (data) => {
      let audio = new Audio(NotificationSound);
      audio.play();
      toast.warning(
        <span style={{ fontSize: 12, fontWeight: "bold" }}>
          {i18n.language === "en" ? data.message_en : data.message_ar}
        </span>
      );
    });

    return () => {
      pusher.unsubscribe(`comment-channel-${localStorage.getItem("userId")}`);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (completedProfile === undefined) {
        getProfileInfo()
          .then((res) => {
            if (res.status && res.status === 201 && res.data.status) {
              localStorage.setItem("completed_profile", res.data.response.updatePercentage === 100 ? "true" : "false");
              setCompletedProfile(res.data.response.updatePercentage === 100 ? true : false);
            }
          })
          .catch((err) => {});
      } else if (localStorage.getItem("completed_profile") !== "true" && window.location.pathname !== "/edit-profile") {
        history.push("/edit-profile");
      }
    }
  }, [completedProfile, history, window.location.pathname]);

  return root === "login" || root === "register" ? null : root === "admin" || root === "accountant" ? (
    <AdminHeader roles={authContext.roles} chatAllowed={chatAllowed} />
  ) : (
    <>
      <Links query={query.get("method_block")} chatAllowed={chatAllowed} />
      <div
        id={"tajah-target-header"}
        className={query.get("method_block") === "site" ? `header_links_none` : `header_links`}
      >
        <div className={`container-fluid flex-box header_links_container`}>
          <NavLink to="/" className="nav-item nav-link header_nav_links" exact>
            {t("Main")}
          </NavLink>
          <NavLink to="/about-us" className="nav-item nav-link header_nav_links ">
            {t("AboutUs")}
          </NavLink>

          <NavLink to="/training-courses" className="nav-item nav-link header_nav_links ">
            {t("courses")}
          </NavLink>
          <NavLink to="/training-programs" className="nav-item nav-link header_nav_links ">
            {t("program")}
          </NavLink>
          <NavLink to="/trainers" className="nav-item nav-link header_nav_links ">
            {t("ProfessionalTrainers")}
          </NavLink>
          <NavLink to="/Exhibition" className="nav-item nav-link header_nav_links">
            {t("Exhibition.Exhibition")}
          </NavLink>
          <NavLink to="/general-surveys" className="nav-item nav-link header_nav_links">
            {t("sidebar.label.generalSurveys")}
          </NavLink>
          <NavLink to="/contactUs" className="nav-item nav-link header_nav_links">
            {t("ConnectUs")}
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default Header;
