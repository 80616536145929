import { Pagination } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IconsTooltip } from "../../../../../Components";
import ViewExhDetailsIcon from "../../../../../assets/icons/eye.svg";
import FilterIcon from "../../../../../assets/icons/filter.svg";
import HomeIcon from "../../../../../assets/icons/home.svg";
import PdfIcon from "../../../../../assets/icons/pdf.svg";
import XlsIcon from "../../../../../assets/icons/xls.svg";
import { utcToLocal } from "../../../../../utils/utcToLocal";
import Breadcrumb from "../../../../Shared/Components/Breadcrumb/Breadcrumb";
import FilterBox from "../../../../Shared/Components/FilterBox/FilterBox";
import MainBox from "../../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainTable from "../../../../Shared/Components/MainTable/MainTable";
import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import {
  exportDiscussionParticipants,
  getAddDiscussionDegree,
  getParticipantsDiscussion,
} from "../../../../../Services/api/DiscussionBoard/DiscussionProvider";
import { Field, Formik } from "formik";
import { exportVotes } from "../../../../../Services/api/votes/VotesProvider";

export default function DiscBoardParticipants({ role }) {
  require("./list.css");

  const { t } = useTranslation();
  const { id, courseId } = useParams();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);

  const [participants, setParticipants] = useState([]);
  const [voteFilter, setDiscussionFilter] = useState({});

  const [discussion, setDiscussion] = useState({});

  const [sortColumns, setSortColumns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilter, setIsFilter] = useState(false);
  const [fetchFilterData, setFetchFilterDate] = useState(false);

  const filterChangeHandler = (e) => {
    const { name, value } = e.target;
    setDiscussionFilter({ ...voteFilter, [name]: value });
  };

  const actionsList = [
    // {
    //   id: "filter",
    //   icon: <img src={FilterIcon} alt="" />,
    //   action: () => {
    //     setFetchFilterDate(true);
    //     if (!fetchFilterData) {
    //       fetchDataForFilter();
    //     }
    //     setIsFilter((val) => !val);
    //   },
    // },
    {
      id: "pdf",
      icon: <img src={PdfIcon} alt="" />,
      theme: "pdf",
      action: () => {
        setIsUpdating(true);
        exportVotes("pdf", id, voteFilter)
          .then((res) => {
            if (res.status && res.status === 200) {
              setIsUpdating(false);
              toast.success(
                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                  {t("export_success")}
                </span>
              );
            } else {
              throw res;
            }
          })
          .catch((err) => {
            setIsUpdating(false);
            toast.error(
              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                {t("export_faild")}
              </span>
            );
          });
      },
    },
    {
      id: "xls",
      icon: <img src={XlsIcon} alt="" />,
      theme: "xls",
      action: () => {
        setIsUpdating(true);
        exportVotes("xlsx", id, voteFilter)
          .then((res) => {
            if (res.status && res.status === 200) {
              setIsUpdating(false);
              toast.success(
                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                  {t("export_success")}
                </span>
              );
            } else {
              throw res;
            }
          })
          .catch((err) => {
            setIsUpdating(false);
            toast.error(
              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                {t("export_faild")}
              </span>
            );
          });
      },
    },
  ];

  const participantActionsList = [
    {
      id: "view-details",
      icon: <img src={ViewExhDetailsIcon} alt="" />,
      title: t("answers"),
      action: (rowId) => {
        history.push(
          `/${role}/course/${courseId}/discussion-board/${id}/participants/${rowId}`
        );
      },
    },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    // {
    //   id: "image",
    //   name: t("Exhibition.photo"),
    //   key: "img",
    //   cell: ({ rowData }) =>
    //     rowData.user.img ? (
    //       <div className=" tw-h-16 tw-w-16 tw-shrink-0 tw-mx-auto">
    //         <img src={rowData.user.img} className="tw-rounded-full tw-h-full tw-aspect-square" />
    //       </div>
    //     ) : (
    //       <></>
    //     ),
    // },
    {
      id: "name",
      name: t("username"),
      key: "name",
      //   sortable: true,
      cell: ({ rowData }) => (
        <span className="cell-font tw-w-36 text2lines">{rowData.name}</span>
      ),
    },
    {
      id: "email",
      name: t("email"),
      key: "email",
      cell: ({ rowData }) => <span className="cell-font">{rowData.email}</span>,
    },
    {
      id: "comments_number",
      name: t("عدد مشاركات"),
      key: "comments_number",
      cell: ({ rowData }) => (
        <span className="cell-font">{rowData.comments_number}</span>
      ),
    },
    {
      id: "vote",
      name: t("vote"),
      key: "vote",
      cell: ({ rowData }) => (
        <span className="cell-font">
          {discussion.vote?.answers?.find(
            (i) => i.id == rowData.vote_answer?.vote_answer_id
          )?.answer ??
            rowData.vote_answer?.vote_answer_id ??
            "-"}
        </span>
      ),
    },
    {
      id: "result",
      name: t("student_result"),
      key: "result",
      cell: ({ rowData }) => (
        <span
          className={`cell-font tw-p-1 tw-rounded ${
            rowData.degree?.id
              ? rowData.degree?.degree >= discussion.success_degree
                ? "tw-text-emerald-600 tw-bg-emerald-600/10"
                : "tw-text-red-600 tw-bg-red-600/10"
              : "tw-text-gray-400 tw-bg-gray-400/10"
          }`}
        >
          {rowData.degree?.id
            ? rowData.degree?.degree >= discussion.success_degree
              ? t("success")
              : t("fail")
            : t("undefined")}
        </span>
      ),
    },
    {
      id: "degree",
      name: t("mark"),
      key: "degree",
      cell: ({ rowData }) => (
        <Formik
          initialValues={{
            interactive_content_id: id,
            user_id: rowData.id,
            degree: rowData.degree?.degree,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            // setSubmitting(true);

            try {
              setIsUpdating(true);
              const res = await getAddDiscussionDegree(values);
              if (res.status == 200) {
                refetchUsers();
              } else {
                throw res;
              }
            } catch (error) {
              console.error(error);
              toast.error(
                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                  {error?.response?.data?.msg ?? t("failed_fetching")}
                </span>
              );
            } finally {
              setIsUpdating(false);
            }
          }}
          validate={(values) => {
            // setHasSubmitted(true);
            const errors = {};
            if (!values.degree && values.degree !== 0) {
              errors.degree = t("crud.errors.required");
            } else if (
              parseInt(values.degree) < 0 ||
              values.degree > discussion.total_degree
            ) {
              errors.degree = t("crud.errors.required");
            }
            return errors;
          }}
          //   validateOnChange={hasSubmitted}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            handleBlur,
            setFieldValue,
          }) => (
            <div className="tw-flex tw-items-center tw-justify-stretch">
              <Field
                className={`tw-grow !tw-rounded-s-full tw-px-4 tw-py-2 tw-bg-gray-100 tw-border-y tw-border-s tw-min-w-fit ${
                  errors.degree ? "tw-border-red-400" : "tw-border-gray-100"
                }`}
                type="number"
                name="degree"
                min={0}
                max={discussion.total_degree}
                placeholder={t("mark")}
              />
              <button
                // type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
                className={`tw-rounded-e-full tw-px-4 tw-py-2 tw-bg-teal-600 tw-text-white tw-border-y tw-border-s tw-border-teal-600 ${
                  isSubmitting ? "tw-border-teal-600/50" : ""
                }`}
              >
                {t("sponsor.save")}
              </button>
            </div>
          )}
        </Formik>
      ),
    },

    // {
    //   id: "createdAt",
    //   name: t("response_date"),
    //   key: "createdAt",
    //   sortable: true,
    //   cell: ({ rowData }) =>
    //     rowData.created_at ? (
    //       <>
    //         {moment(rowData?.created_at).format("DD/MM/YYYY")}
    //         <br />
    //         {utcToLocal(rowData?.created_at)}
    //       </>
    //     ) : (
    //       <></>
    //     ),
    // },
    // {
    //   id: "actions",
    //   name: t("Exhibition.actions"),
    //   key: "actions",
    //   cell: ({ rowData, rowIndex }) => (
    //     <div className={`${classes["exhibition_list__actions"]} tw-flex tw-items-center tw-justify-center`}>
    //       {participantActionsList.map((action, index) => (
    //         <IconsTooltip
    //           title={action.title}
    //           content={
    //             <button
    //               key={action.id}
    //               className={classes["exhibition_list__actions-btn"]}
    //               onClick={() => {
    //                 action.action(rowData.id, rowIndex);
    //               }}
    //             >
    //               {action.icon}
    //             </button>
    //           }
    //         />
    //       ))}
    //     </div>
    //   ),
    // },
  ];

  const filterFields = [
    // search
    {
      id: "search",
      component: "input",
      type: "search",
      placeholder: t("search_by_name_or_email"),
      label: t("search_by_name_or_email"),
      name: "search",
      value: voteFilter.search ?? "",
      classes: "col-12 col-md-8",
      onReset: () =>
        filterChangeHandler({ target: { value: "", name: "search" } }),
      onchange: filterChangeHandler,
    },
    // reset
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t(`Exhibition.reset`),
      classes: "col-12 col-md-2",
      backgroundColor: "white",
      color: "rgb(4, 108, 119)",
      borderColor: "rgb(4, 108, 119)",
      action: () => {
        setDiscussionFilter({});
        if (currentPage == 1) {
          refetchUsers({});
        } else {
          setCurrentPage(1);
        }
      },
    },
    // submit
    {
      id: "button",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-2",
      action: () => {
        if (currentPage == 1) {
          refetchUsers();
        } else {
          setCurrentPage(1);
        }
      },
    },
  ];

  function fetchDataForFilter() {}

  const fetchUsers = (params = voteFilter) => {
    params = { ...params, perPage: 10 };
    setIsUpdating(true);
    const myPromise = new Promise(async (resolve, reject) => {
      try {
        let response = await getParticipantsDiscussion(id, params);
        if (response.status == 200) {
          setParticipants(response.data.data.participants);
          setDiscussion(response.data.data.interactive_content);
          setIsLoading(false);
          setIsUpdating(false);
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });

    return myPromise;
  };

  const sortActionHandler = async (columnKey) => {
    let columns = [...sortColumns];
    let key = columnKey;
    // Map the local key to the backend key
    switch (columnKey) {
      case "name":
        key = "name";
        break;
      case "createdAt":
        key = "created_at";
        break;
    }

    const col = columns.find((c) => c.key == key);
    if (col) {
      columns = [
        {
          key,
          orderBy: col.orderBy == "ASC" ? "DESC" : "ASC",
        },
      ];
    } else {
      columns = [
        {
          key,
          orderBy: "ASC",
        },
      ];
    }
    setSortColumns(columns);
  };

  const refetchUsers = async (params = voteFilter) => {
    try {
      let customFilter = {
        ...params,
        sortColumns,
        page: currentPage,
      };
      await fetchUsers(customFilter);
    } catch (e) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  useEffect(() => {
    refetchUsers();
  }, [currentPage, sortColumns]);

  const breadcrumbList = [
    role != "trainee"
      ? {
          id: "home",
          page: <img src={HomeIcon} alt="" />,
          pagePath: "/",
        }
      : {
          id: t("admin.label.admin_label"),
          page: t("admin.label.admin_label"),
          pagePath: "/admin",
        },
    {
      id: t("sidebar.label.courses"),
      page: t("sidebar.label.courses"),
      pagePath: `/${role}/courses`,
    },
    {
      id: "course",
      page: t("Courseoutline"),
      pagePath: `/${role}/course/${courseId}`,
    },
    {
      id: id,
      page: discussion?.name,
      pagePath: `/${role}/course/${courseId}/discussion-board`,
    },
    {
      id: 1,
      page: t("participants"),
      active: true,
    },
  ];

  return (
    <>
      <div className="container-fluid" id={"content-section"}>
        <div className="row">
          <div className="col-12 sm:tw-px-2 tw-py-8">
            {/*TODO*/}
            <Breadcrumb list={breadcrumbList} />
          </div>
        </div>
        {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
        {!isLoading ? (
          <MainBox>
            <MainBoxHead title={t("Participants_menu")} actions={actionsList} />
            {isFilter && <FilterBox fields={filterFields} />}

            <div className="tw-flex tw-space-s-4 tw-flex-wrap tw-items-center tw-justify-between tw-gap-4 tw-my-4">
              <div className="tw-flex tw-items-center tw-px-4 tw-py-2 tw-rounded-lg tw-bg-slate-50 tw-space-s-3">
                <div className="tw-text-gray-500 tw-whitespace-nowrap">
                  {t("total_mark")}:
                </div>
                <div className="tw-font-bold">{discussion?.total_degree}</div>
              </div>
              <div className="tw-flex tw-items-center tw-px-4 tw-py-2 tw-rounded-lg tw-bg-slate-50 tw-space-s-3">
                <div className="tw-text-gray-500 tw-whitespace-nowrap">
                  {t("successs_mark")}:
                </div>
                <div className="tw-font-bold">{discussion?.success_degree}</div>
              </div>
              {discussion?.vote?.question && (
                <div className="tw-flex tw-items-center tw-px-4 tw-py-2 tw-rounded-lg tw-bg-slate-50 tw-space-s-3">
                  <div className="tw-text-gray-500 tw-whitespace-nowrap">
                    {t("vote_text")}:
                  </div>
                  <div
                    className="tw-font-bold"
                    dangerouslySetInnerHTML={{
                      __html: discussion?.vote?.question,
                    }}
                  />
                </div>
              )}
            </div>
            <MainTable
              columns={columns}
              data={participants}
              sortAction={sortActionHandler}
            />
          </MainBox>
        ) : (
          <div
            className="mt-5"
            style={{
              height: "65vh",
            }}
          >
            <SkeletonCard />
          </div>
        )}
      </div>
    </>
  );
}
