import { Redirect, Route, Switch } from "react-router-dom";
import AlreadyVerified from "./AlreadyVerified/AlreadyVerified";
import Failed from "./Failed/Failed";
import Verified from "./Verified/Verified";

function EmailVerificationRoutes(props) {
  return (
    <Switch>
      <Route exact path={`${props.match.path}/success`} component={Verified} />
      <Route exact path={`${props.match.path}/already-verified`} component={AlreadyVerified} />
      <Route exact path={`${props.match.path}/failed`} component={Failed} />
      <Redirect to="/page-not-found" />
    </Switch>
  );
}

export default EmailVerificationRoutes;
