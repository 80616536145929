import { useContext } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import withRoot from "../../../../Hoc/withRoot";
import RouteInterceptor from "../../../../Interceptors/RouteInterceptor";
import { AuthContext } from "../../../../Services/api/auth/AuthContext";
import { CouponDetails, CreateCoupon, EditCoupon, ProgramPaymentsReport } from "../../../../routes/admins";
import handleRoutesPaths from "../../../../routes/main/HandlePaths";
import routesUnprotected from "../../../../routes/main/RoutesUnprotected";
import Coupons from "../../../Admin/Coupon/Coupons/Coupons";
import Dashboard from "../../../Admin/Dashboard/Dashboard";
import PercentageTrainersList from "../../../Admin/Financial/PercentageTrainers/PercentageTrainersList";
import ReportFinancial from "../../../Admin/Financial/Report/Report";
import TotalIncomeReport from "../../../Admin/Financial/Report/TotalIncomeReport";
import Fund from "../../../Admin/Financial/fund/fund";
import Offers from "../../../Admin/Financial/offers/Offers";
import AddOffers from "../../../Admin/Financial/offers/add/Add";
import EditOffers from "../../../Admin/Financial/offers/edit/Edit";
import PeymentRequestsCoursesPrograms from "../../../Admin/Financial/peymentRequestsCourses/PeymentRequestsCoursesPrograms";
import ProgramPaymentListFinancial from "../../../Admin/Financial/programPaymentDate/ProgramPaymentList";
import RefundList from "../../../Admin/Financial/refund/RefundList";
import EditRefundSetting from "../../../Admin/Financial/refundSetting/Edit/Edit";
import RefundSettingList from "../../../Admin/Financial/refundSetting/RefundSettingList";
import AddRefundSetting from "../../../Admin/Financial/refundSetting/add/Add";
import AddSpon from "../../../Admin/Financial/sponsor/Add";
import AddNewTrainee from "../../../Admin/Financial/sponsor/AddNewTrainee";
import Sponsor from "../../../Admin/Financial/sponsor/Sponsor";
import Tax from "../../../Admin/Financial/tax/Tax";
import AddTax from "../../../Admin/Financial/tax/add/Add";
import EditTax from "../../../Admin/Financial/tax/edit/Edit";
import UserWallet from "../../../Admin/Financial/userWallet/UserWallet";
import WalletDetails from "../../../Admin/Financial/userWallet/walletDetails/walletDetails";
import AddTicket from "../../../Admin/Tickets/Add/Add";
import ListTickets from "../../../Admin/Tickets/List/List";
import ViewTicket from "../../../Admin/Tickets/View/View";
import BankTransfers from "../../../Admin/bankTransfers/bankTransfers";
import EmailVerificationRoutes from "../../../EmailVerification/Routes";
import Payments from "../../../Payments/Payments";
import AddSponsor from "../../../Sponsor/AddSponsor";
import Invoice from "../../../Sponsor/Invoice/Invoice";
import Sponsors from "../../../Sponsor/Sponsors";
import AddTraineeByEmail from "../../../Sponsor/addTrainee/AddTraineeByEmail";
import AddTraineeByName from "../../../Sponsor/addTrainee/AddTraineeByName";
import Admin from "../Admin/Admin";
import Manager from "../Manager/Manager";
import RateTicket from "../Tickets/Rate/Rate";
import Trainer from "../Trainer/Trainer";
import AccountantProvider from "../accountant/AccountantContext";

function Routes() {
  const authContext = useContext(AuthContext);
  let is_logged_in = localStorage.getItem("token") === null ? false : true;

  return (
    <Switch>
      {authContext.roles.includes("admin") ? (
        <RouteInterceptor path="/admin" exact={false} component={Admin} show={true} />
      ) : null}
      {authContext.roles.includes("trainer") && (
        <RouteInterceptor path="/trainer" exact={false} component={Trainer} show={true} />
      )}
      {authContext.roles.includes("manager") && (
        <RouteInterceptor path="/manager" exact={false} component={Manager} show={true} />
      )}
      <RouteInterceptor path="/verify" exact={false} component={EmailVerificationRoutes} show={true} />
      {is_logged_in ? (
        <>
          <Switch>
            {handleRoutesPaths.map((route, index) => (
              <Route path={route.path} exact={route.exact} component={route.component} key={index} />
            ))}
            <Route path={"/ticket/view/:id"} exact component={ViewTicket} />

            <Route path={"/tickets"} exact component={ListTickets} />

            <Route path={"/ticket/add"} exact component={AddTicket} />
            <Route path={"/ticket/rate/:id"} exact component={RateTicket} />
            {!authContext.roles.includes("accountant") ? <Redirect to={"/private-profile"} /> : null}
          </Switch>
          {authContext.roles.includes("accountant") ? (
            <AccountantProvider>
              <Switch>
                <Route exact={true} path={"/accountant"} component={Dashboard} />
                <Route component={CreateCoupon} path="/accountant/coupon/add" exact={true} />
                <Route component={EditCoupon} path="/accountant/coupon/edit/:id" exact={true} />
                <Route component={() => <Coupons type="notGift" />} path="/accountant/coupon" exact={true} />
                <Route component={() => <Coupons type="gift" />} path="/accountant/gift-coupon" exact={true} />
                <Route component={() => <CouponDetails type="notGift" />} path="/accountant/coupon/:id" exact={true} />
                <Route
                  component={() => <CouponDetails type="gift" />}
                  path="/accountant/gift-coupon/:id"
                  exact={true}
                />
                <Route component={AddOffers} path="/accountant/offer/add" exact={true} />
                <Route component={EditOffers} path="/accountant/offer/edit/:id" exact={true} />
                <Route component={Offers} path="/accountant/offer" exact={true} />
                <Route component={AddTax} path="/accountant/tax/add" exact={true} />
                <Route component={EditTax} path="/accountant/tax/edit/:id" exact={true} />
                <Route component={Tax} path="/accountant/tax" exact={true} />
                <Route component={ReportFinancial} path="/accountant/financial/report" exact={true} />
                <Route component={() => <RefundList type="trainee" />} path="/accountant/refund" exact={true} />
                <Route
                  component={() => <RefundList type="exhibition-visitors" />}
                  path="/accountant/refund/exhibition-visitors"
                  exact={true}
                />
                <Route
                  component={() => <RefundList type="exhibition-booths" />}
                  path="/accountant/refund/exhibition-booths"
                  exact={true}
                />
                <Route
                  component={() => <BankTransfers role="accountant" />}
                  path="/accountant/bank-transfers"
                  exact={true}
                />
                <Route component={Sponsor} path="/accountant/sponsor" exact={true} />
                <Route component={AddNewTrainee} path="/accountant/add/sponsor/:id" exact={true} />
                <Route component={AddSpon} path="/accountant/add/sponsor" exact={true} />
                <Route component={ProgramPaymentListFinancial} path="/accountant/program-payment-date" exact={true} />
                <Route
                  component={() => <ProgramPaymentsReport role="accountant" />}
                  path="/accountant/program/:id/payments-report"
                  exact={true}
                />
                <Route
                  component={() => <ProgramPaymentsReport role="accountant" backPath={"program-payment-date"} />}
                  path="/accountant/program-payment-date/program/:id/payments-report/:paymentId"
                  exact={true}
                />
                <Route component={PercentageTrainersList} path="/accountant/percentage-trainer/list" exact={true} />
                <Route
                  component={PeymentRequestsCoursesPrograms}
                  path="/accountant/peyment-requests-courses"
                  exact={true}
                />
                <Route
                  component={() => <PeymentRequestsCoursesPrograms isProgram={true} />}
                  path="/accountant/peyment-requests-programs"
                  exact={true}
                />
                <Route
                  component={() => <Payments type="financial" />}
                  path="/accountant/financial-movement"
                  exact={true}
                />
                <Route component={Fund} path="/accountant/fund" exact={true} />
                <Route exact={true} path={"/accountant/sponsors"} component={Sponsors} />
                <Route exact={true} path={"/accountant/sponsor/addsponsor"} component={AddSponsor} />
                <Route exact={true} path={"/accountant/sponsor/addtraineebyname"} component={AddTraineeByName} />
                <Route exact={true} path={"/accountant/sponsor/addtraineebyemail"} component={AddTraineeByEmail} />
                <Route exact={true} path={"/accountant/invoice"} component={Invoice} />
                <Route exact={true} path={"/accountant/users-wallet"} component={UserWallet} />
                <Route exact={true} path={"/accountant/users-wallet/details/:id"} component={WalletDetails} />
                <Route exact={true} path={"/accountant/financial/total-income-report"} component={TotalIncomeReport} />

                <Route
                  exact={true}
                  path={"/accountant/refund-setting/list"}
                  component={() => <RefundSettingList type="trainee" />}
                />
                <Route
                  exact={true}
                  path={"/accountant/refund-setting/list/exhibition-visitors"}
                  component={() => <RefundSettingList type="exhibition-visitors" />}
                />
                <Route
                  exact={true}
                  path={"/accountant/refund-setting/list/exhibition-booths"}
                  component={() => <RefundSettingList type="exhibition-booths" />}
                />
                <Route
                  exact={true}
                  path={"/accountant/refund-setting/add"}
                  component={() => <AddRefundSetting type="trainee" />}
                />
                <Route
                  exact={true}
                  path={"/accountant/refund-setting/exhibition-visitors/add"}
                  component={() => <AddRefundSetting type="exhibition-visitors" />}
                />
                <Route
                  exact={true}
                  path={"/accountant/refund-setting/exhibition-booths/add"}
                  component={() => <AddRefundSetting type="exhibition-booths" />}
                />
                <Route
                  exact={true}
                  path={"/accountant/refund-setting/edit/:id"}
                  component={() => <EditRefundSetting type="trainee" />}
                />
                <Route
                  exact={true}
                  path={"/accountant/refund-setting/exhibition-visitors/edit/:id"}
                  component={() => <EditRefundSetting type="exhibition-visitors" />}
                />
                <Route
                  exact={true}
                  path={"/accountant/refund-setting/exhibition-booths/edit/:id"}
                  component={() => <EditRefundSetting type="exhibition-booths" />}
                />
              </Switch>
            </AccountantProvider>
          ) : null}
        </>
      ) : (
        <Switch>
          {routesUnprotected.map((route, index) => (
            <Route path={route.path} exact={route.exact} component={route.component} key={index} />
          ))}
          <Redirect to="/page-not-found" />
        </Switch>
      )}

      <Redirect to="/page-not-found" />
    </Switch>
  );
}

export default withRouter(withRoot(Routes));
