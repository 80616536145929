import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import tebxLogo from "../../../../assets/icons/footer_logo.svg";

function Footer(props) {
  require("./footer.css");
  const { t } = useTranslation();
  const location = useLocation();

  return location.pathname !== "/login" &&
    location.pathname !== "/register" &&
    location.pathname !== "/forgot-password" &&
    location.pathname !== "/email-verification" ? (
    <>
      <footer
        className={props.query === "site" ? "footer_content_hide" : "footer_content"}
        style={{ marginTop: location.pathname === "/" ? 0 : "" }}
      >
        <div className="container-fluid">
          <div className="footer-grid">
            <div className="logo-flex">
              <img src={tebxLogo} alt="" />
            </div>
            <div className="footer_col_cont">
              <div>
                <h3>{t("Departments")}</h3>

                <ul className="list_UL">
                  <li>
                    <NavLink className="NavLink_footer" to="/training-courses">
                      {t("home.courses")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="NavLink_footer" to="/training-programs">
                      {t("home.programs")}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink className="NavLink_footer" to="/trainers">
                      {t("home.Trainers")}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink className="NavLink_footer" to="/Exhibition">
                      {t("home.Exhibitions")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="NavLink_footer" to="/general-surveys">
                      {t("home.Surveys")}
                    </NavLink>
                  </li>
                </ul>
              </div>

              <div>
                <h3>{t("Location")}</h3>
                <div className="">
                  <ul className="list_UL">
                    <li>{t("uniName")}</li>
                    <li>{t("uniLocation")}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer_col_cont">
              <div>
                <h3>{t("Quick_Links")}</h3>
                <div className="">
                  <ul className="list_UL">
                    <li>
                      <NavLink className="NavLink_footer" to="/guidelines">
                        {t("Guidelines")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="NavLink_footer" to={localStorage.getItem("token") ? "/tickets" : "/login"}>
                        {t("Helpdesk")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="NavLink_footer" to="/faq/faq-list">
                        {t("FAQ.common_questions")}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <h3>{t("Call_Us_At")}</h3>
                <div className="">
                  <ul className="list_UL">
                    <li dir="ltr">+966 133331313</li>
                    <li dir="ltr">
                      <a href="mailto: elearning@iau.edu.sa">elearning@iau.edu.sa</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer_col_cont">
              <div>
                <h3>{t("about_TEB_X")}</h3>
                <div className="">
                  <ul className="list_UL">
                    <li>
                      <NavLink className="NavLink_footer" to="/about-us">
                        {t("AboutUs")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="NavLink_footer" to="/privacy-policy">
                        {t("PrivacyPolicy")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="NavLink_footer" to="/terms-and-conditions">
                        {t("TermsAndConditions")}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>

              <div>
                <h3> {t("Follow_us")} </h3>
                <ul className="social_media_spactial">
                  <li>
                    <a href="/">
                      <i className="fab fa-facebook facebook-icon"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fab fa-twitter twitter-icon"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fab fa-instagram instagram-icon"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fab fa-youtube youtube-icon"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div
        className={props.query === "site" ? "footer_hide" : "footer"}
        style={{
          marginTop: 0,
        }}
      >
        <div className="footer_nav">
          <div className="container-fluid">
            <div className="footer_link">
              <div className="footer_nav_copy">
                {t("AllRightsReservedToTebXSite")}&nbsp;
                {new Date().getFullYear()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
}

export default Footer;
